/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

/* eslint-disable import/prefer-default-export */
export const todayInOneYear = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    return date
}

export const preventFollowingLink = event => {
    // Ignore fake events generated by Firefox when pressing Enter
    if (
        event.clientX === 0 &&
        event.clientY === 0 &&
        event.pageX === 0 &&
        event.pageY === 0 &&
        event.screenX === 0 &&
        event.screenY === 0
    ) {
        event.preventDefault()
    }
}
